<template>
  <div class="flex-1 h-full flex flex-col">
    <div
      v-if="
        currentWorkingVersion?.key &&
          (currentWorkingVersion?.status == 'submitted' || currentWorkingVersion?.status == 'pre_approved')
      "
    >
      <fw-message type="warning" icon="actions" class="mb-5">
        <div class="font-bold text-base">
          Nova versão <span v-if="currentWorkingVersion?.version">v.{{ currentWorkingVersion.version }} </span>em
          aprovação
        </div>
        <div>
          Existe uma nova versão com pedido de alterações, que aguarda aprovação. Essas alterações não estão refletidas
          nesta página.
        </div>
      </fw-message>
    </div>
    <div class="flex gap-5">
      <div class="flex-1 flex gap-3">
        <div>
          <fw-tag v-if="userMap.version" size="lg" type="primary">v.{{ userMap.version }}</fw-tag>
        </div>
        <div>
          <fw-heading muted size="h3">Versão atual do seu mapa de férias</fw-heading>
          <div class="text-sm font-medium text-gray-500">Última versão aprovada.</div>
        </div>
      </div>
      <!-- <div v-if="$device.isWDesktop()">
        <b-dropdown aria-role="list" position="is-bottom-left">
          <fw-button-dropdown slot="trigger" aria-role="listitem" type="xlight" :label="$t(`views.${view}`)">
            <fw-icon-list-row v-if="view === 'details'" class="h-4 w-4 mr-1" />
            <fw-icon-calendar v-else class="h-4 w-4 mr-1" /> {{ $t(`views.${view}`) }}
          </fw-button-dropdown>
          <b-dropdown-item
            label="Detalhes"
            aria-role="listitem"
            :class="`font-medium ${view == 'details' ? 'text-primary' : 'text-gray-500'}`"
            @click.native="view = 'details'"
          >
            <div class="flex items-center"><fw-icon-list-row class="h-4 w-4 mr-1" /> Vista de detalhes</div>
          </b-dropdown-item>
          <b-dropdown-item
            label="Ano"
            aria-role="listitem"
            :class="`font-medium ${view == 'year-calendar' ? 'text-primary' : 'text-gray-500'}`"
            @click.native="view = 'year-calendar'"
          >
            <div class="flex items-center"><fw-icon-calendar class="h-4 w-4 mr-1" /> Vista em calendário</div>
          </b-dropdown-item>
        </b-dropdown>
      </div> -->
    </div>
    <div class="py-4 flex-1 flex flex-col gap-5">
      <div>
        <ContentLoader v-if="loading" type="row"></ContentLoader>
        <div v-else-if="map?.key && userMap?.key">
          <BlockYearActivity
            :data="yearActivity.data"
            :custom-class="yearActivity.colors"
            :custom-icons="yearActivity.icons"
            :year="map.year"
            :max-value="1"
            :christmas-tree="false"
            expandable
          />
          <PanelMapUserDaysList
            :user-map="userMap"
            :days="mapUserDays"
            :selected-days="selectedDays"
            :changed-days="{}"
            :year="map.year"
            :validations="validations"
            :is-latest-user-map="true"
          />
          <!-- <PanelCalendar
            v-show="view != 'details'"
            :type="view == 'year-calendar' ? 'year' : 'month'"
            :min-date="new Date(map.year, 0, 1)"
            :max-date="new Date(map.year, 11, 31)"
            :attributes="calendarAttributes"
          /> -->
        </div>
      </div>
    </div>

    <fw-panel-info debug label="Map data (raw)">
      <json-viewer :value="{ map, userMap, mapUserDays, validations, calendarAttributes }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
// import PanelCalendar from '@/components/panels/PanelCalendar'
import {
  MAP_USER_STATUS,
  MAP_USER_STATUS_COLORS,
  FULL_DAY_TYPES,
  PARTIAL_DAY_TYPES,
  MAP_USER_VERSION_REASONS,
  VACATIONS_DAY_TYPES,
  CREDIT_DAY_TYPES
} from '@/utils/index.js'
import BlockYearActivity from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockYearActivity'
import PanelMapUserDaysList from '@/components/panels/PanelMapUserDaysList'
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'

export default {
  components: {
    // PanelCalendar,
    PanelMapUserDaysList,

    ContentLoader,
    BlockYearActivity
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    map: {
      type: Object,
      default: () => {}
    },
    userMap: {
      type: Object,
      default: () => {}
    },
    mapUserDays: {
      type: Array,
      default: () => []
    },
    validations: {
      type: Object,
      default: () => {}
    },
    calendarAttributes: {
      type: Array,
      default: () => []
    },
    yearActivity: {
      type: Object,
      default: () => {}
    },
    currentWorkingVersion: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      colors: Object.freeze(MAP_USER_STATUS_COLORS),
      statusLabels: Object.freeze(MAP_USER_STATUS),
      vacationDayTypes: Object.freeze(VACATIONS_DAY_TYPES),
      creditDayTypes: Object.freeze(CREDIT_DAY_TYPES),
      fullDayTypes: Object.freeze(FULL_DAY_TYPES),
      partialDayTypes: Object.freeze(PARTIAL_DAY_TYPES),
      versionsMotives: Object.freeze(MAP_USER_VERSION_REASONS)
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language
    },

    selectedDays() {
      let days = {}

      // Get days from previous version that were not changed in this version
      for (let index = 0; index < this.mapUserDays.length; index++) {
        const day = this.mapUserDays[index]
        days[day.date] = day
      }

      // Remove del/acc days
      for (const [date, day] of Object.entries(days)) {
        if (day.sync_operation != 'add') {
          delete days[date]
        }
      }

      return days
    }
  },

  methods: {}
}
</script>

<i18n>
{
  "en": {
    "applyFilters": "Apply filters",
    "activity": "Atividade",
    "comments": "Mensagens",
    "syncOperations": {
      "add": "Adicionado",
      "del": "Removido",
      "acc": "Cumulado"
    },
    "views": {
      "details": "Detalhes",
      "year-calendar": "Calendário",
      "year-month": "Mês"
    },
    "types": {
      "morning": "manhã",
      "afternoon": "tarde",
      "halfday": "",
      "creditday-morning": "manhã",
      "creditday-afternoon": "tarde",
      "creditday-halfday": ""
    }
  },
  "pt": {
    "applyFilters": "Aplicar filtros",
    "activity": "Atividade",
    "comments": "Mensagens",
    "syncOperations": {
      "add": "Adicionado",
      "del": "Removido",
      "acc": "Cumulado"
    },
    "views": {
      "details": "Detalhes",
      "year-calendar": "Calendário",
      "year-month": "Mês"
    },
    "types": {
      "morning": "manhã",
      "afternoon": "tarde",
      "halfday": "",
      "creditday-morning": "manhã",
      "creditday-afternoon": "tarde",
      "creditday-halfday": ""
    }
  }
}
</i18n>

<style>
.b-checkbox {
  border-radius: inherit !important;
}
</style>
